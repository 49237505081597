import urls from 'config/urls';

const QUERY_PARAM = 'campaignKey';
const QUERY_PARAM_VALUE = 'campaignData';
const LOCAL_STORAGE_KEY = 'mentorise-compaign';
const LOCAL_STORAGE_KEY_VALUE = 'mentorise-compaign-value';

const NEW_MENTOR = 'mentorise-campaign/new-mentor';
const INCOMPLETE_MENTOR = 'mentorise-campaign/incomplete-mentor';
const JOIN_ORGANIZATION = 'mentorise-campaign/join-organization';
const ERROR_EMAIL_VALIDATION ='mentorise-campaign/error-email-validation'

const campaigns = {
  [NEW_MENTOR]: {
    becomeMentor: true,
    redirect: urls.MY_ACCOUNT.path,
  },

  [INCOMPLETE_MENTOR]: {
    endsAfterRedirect: true,
    redirect: urls.MY_ACCOUNT.path,
  },

  [JOIN_ORGANIZATION]: {
    joinOrganization: true,
    endsAfterRedirect: false,
    redirect: urls.MY_ACCOUNT.path,
  },
  
  [ERROR_EMAIL_VALIDATION]: {
    emailValidation: true,
    redirect: urls.HOME,
  },
};

export function tryReadCampaignFromUrl() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const campaignKey = urlParams.get(QUERY_PARAM);
  if (!campaignKey) {
    return;
  }
  const value = urlParams.get(QUERY_PARAM_VALUE);
  localStorage.setItem(LOCAL_STORAGE_KEY, campaignKey);
  localStorage.setItem(LOCAL_STORAGE_KEY_VALUE, value);
  return { ...campaigns[campaignKey], value };
}

export function tryGetCurrentCampaign() {
  const campaignKey = localStorage.getItem(LOCAL_STORAGE_KEY);
  const campaign = campaignKey && campaigns[campaignKey];
  if (!campaign) {
    return;
  }
  return { ...campaign, value: localStorage.getItem(LOCAL_STORAGE_KEY_VALUE) };
}

export function finishCampaign() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}
