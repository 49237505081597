import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import urls from 'config/urls';
import MtsSpinner from 'components/mts-spinner';

const Home = lazy(() => import('containers/home'));
const Teacher = lazy(() => import('containers/teacher'));
const Mentors = lazy(() => import('containers/mentors'));
const Mentor = lazy(() => import('containers/mentor'));
const Payment = lazy(() => import('containers/payment'));
const Rating = lazy(() => import('containers/evaluation'));
const MyAccount = lazy(() => import('containers/my-account'));
const Login = lazy(() => import('containers/login'));
const ForgotPassword = lazy(() => import('containers/forgot-password'));
const ResetPassword = lazy(() => import('containers/reset-password'));
const Register = lazy(() => import('containers/register'));
const Terms = lazy(() => import('containers/terms'));
const PrivacyPolicies = lazy(() => import('containers/privacy-policies'));
const DashboardAdmin = lazy(() => import('containers/dashboard-admin'));
const InAppMail = lazy(() => import('containers/in-app-mail'));
const Mentoring = lazy(() => import('containers/mentoring'));
const RescheduleMentoring = lazy(() =>
  import('containers/reschedule-mentoring')
);
const ConfirmProposedSchedule = lazy(() =>
  import('containers/confirm-proposed-schedule')
);
const ProposeSchedule = lazy(() => import('containers/propose-schedule'));
const ConfirmReschedule = lazy(() => import('containers/confirm-reschedule'));
const MtsB2bAdminLayout = lazy(() =>
  import('components/layouts/mts-b2b-admin-layout')
);
const Historic = lazy(() => import('containers/b2b-historic'));
const Members = lazy(() => import('containers/b2b-members'));
const MtsB2bAppContainer = lazy(() =>
  import('components/mts-b2b-app-container')
);
const SmartMatch = lazy(() => import('containers/b2b-smart-match'));
const B2BDashboard = lazy(() => import('containers/b2b-dashboard'));
const Departments = lazy(() => import('containers/b2b-departments'));
const ConfirmMentoring = lazy(() => import('containers/confirm-mentoring'));
const ForEnterprises = lazy(() => import('containers/for-enterprises'));
const MentorFeedback = lazy(() => import('containers/mentor-feedback'));
const CookiesPolicy = lazy(() => import('containers/cookies-policy'));

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={<MtsSpinner />}>
      <Switch>
        <Route
          path={urls.FOR_ENTERPRISES.path}
          exact={true}
          component={ForEnterprises}
        />
        <Route
          path={urls.COOKIES_POLICY.path}
          exact={true}
          component={CookiesPolicy}
        />
        <Route path={urls.TEACHER.path} exact={true} component={Teacher} />
        <Route path={urls.LOGIN.path} exact={true} component={Login} />
        <Route
          path={urls.LOGIN_FROM_LINKEDIN.path}
          exact={true}
          component={Login}
        />
        <Route
          path={urls.FORGOT_PASSWORD.path}
          exact={true}
          component={ForgotPassword}
        />
        <Route
          path={urls.RESET_PASSWORD.path}
          exact={true}
          component={ResetPassword}
        />
        <Route path={urls.REGISTER.path} exact={true} component={Register} />
        <Route path={urls.TERMS.path} exact={true} component={Terms} />
        <Route
          path={urls.MENTORING_WAITING.path}
          exact={true}
          component={Mentoring}
        />
        <Route
          path={urls.RESCHEDULE.path}
          exact={true}
          component={RescheduleMentoring}
        />
        <Route
          path={urls.PROPOSE_SCHEDULE.path}
          exact={true}
          component={ProposeSchedule}
        />
        <Route
          path={urls.CONFIRM_PROPOSED_SCHEDULE.path}
          exact={true}
          component={ConfirmProposedSchedule}
        />
        <Route
          path={urls.CONFIRM_RESCHEDULE.path}
          exact={true}
          component={ConfirmReschedule}
        />
        <Route
          path={urls.CONFIRM_MENTORING.path}
          exact={true}
          component={ConfirmMentoring}
        />
        <Route
          path={urls.MENTORING_CALL.path}
          exact={true}
          component={Mentoring}
        />
        <Route
          path={urls.PRIVACY_POLICIES.path}
          exact={true}
          component={PrivacyPolicies}
        />

        <Route
          path={urls.DASHBOARD_ADMIN_ORGANIZATION.path}
          exact={true}
          component={DashboardAdmin}
        />
        <Route
          path={urls.DASHBOARD_ADMIN.path}
          exact={true}
          component={DashboardAdmin}
        />
        <Route path={`${process.env.REACT_APP_BASE_ROUTE}b2b-admin`}>
          <MtsB2bAdminLayout>
            <Route path={urls.B2B_DASHBOARD.path} component={B2BDashboard} />
            <Route path={urls.HISTORIC.path} component={Historic} />
            <Route path={urls.MEMBERS.path} component={Members} />
            <Route path={urls.SMART_MATCH.path} component={SmartMatch} />
            <Route path={urls.DEPARTMENTS.path} component={Departments} />
          </MtsB2bAdminLayout>
        </Route>

        <MtsB2bAppContainer>
          <Route path={urls.HOME.path} exact={true} component={Home} />
          <Route
            path={urls.MY_ACCOUNT.path}
            exact={true}
            component={MyAccount}
          />
          <Route path={urls.RATING.path} exact={true} component={Rating} />
          <Route
            path={urls.IN_APP_MAIL.path}
            exact={true}
            component={InAppMail}
          />
          <Route path={urls.MENTOR.path} exact={true} component={Mentor} />
          <Route path={urls.MENTORS.path} exact={true} component={Mentors} />
          <Route path={urls.PAYMENT.path} exact={true} component={Payment} />
          <Route
            path={urls.MENTOR_FEEDBACK.path}
            exact={true}
            component={MentorFeedback}
          />
        </MtsB2bAppContainer>
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default Routes;
